<form #form="ngForm">
    <!-- Employment -->
    <h2 class="ui header">{{ 'forms.add-terminated.job.employment.title' | translate }}</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>{{
                'forms.add-terminated.job.employment.employmentType' | translate
            }}</mat-label>
            <mat-select
                required
                name="employmentType"
                placeholder="{{ 'forms.add-terminated.job.employment.employmentTypePlaceholder' | translate }}"
                [(ngModel)]="employee.employmentType"
                #employmentType="ngModel"
            >
                <mat-option *ngFor="let employmentType of employmentTypes" [value]="employmentType">{{
                    employmentType | appTranslateOptions: employmentTypeDisplayOptions
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="employmentType.invalid">{{ employmentType.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.job.employment.hireDate' | translate }}</mat-label>
            <input
                matInput
                placeholder="{{ 'forms.add-terminated.job.employment.datePlaceholder' | translate }}"
                required
                name="hiredAt"
                #hiredAt="ngModel"
                [(ngModel)]="employee.hiredAt"
                [matDatepicker]="hiredAtPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="hiredAtPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #hiredAtPicker></mat-datepicker>
            <mat-error *ngIf="hiredAt.invalid">{{ hiredAt.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.job.employment.lastDayOfWork' | translate }}</mat-label>
            <input
                matInput
                placeholder="{{ 'forms.add-terminated.job.employment.datePlaceholder' | translate }}"
                required
                name="{{ 'forms.add-terminated.job.employment.lastDayOfWorkPlaceholder' | translate }}"
                #lastDayOfWork="ngModel"
                [(ngModel)]="employee.lastDayOfWorkOn"
                [matDatepicker]="lastDayOfWorkOnPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="lastDayOfWorkOnPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #lastDayOfWorkOnPicker></mat-datepicker>
            <mat-error *ngIf="lastDayOfWork.invalid">{{ lastDayOfWork.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.add-terminated.job.employment.office' | translate }}</mat-label>
            <mat-select name="office" required #office="ngModel" [(ngModel)]="employee.office">
                <mat-option *ngFor="let office of offices" [value]="office">{{ office.name }}</mat-option>
            </mat-select>
            <mat-error *ngIf="office.invalid">{{ office.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <!-- Termination -->
    <h2 class="ui header">{{ 'forms.add-terminated.job.termination.title' | translate }}</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.job.termination.terminationDate' | translate }}</mat-label>
            <input
                matInput
                placeholder="{{ 'forms.add-terminated.job.termination.terminationDatePlaceholder' | translate }}"
                required
                name="terminatedAt"
                #terminatedDate="ngModel"
                [ngModel]="terminatedOn"
                (ngModelChange)="setTerminatedOn($event)"
                [matDatepicker]="terminatedAtPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="terminatedAtPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #terminatedAtPicker></mat-datepicker>

            <mat-error *ngIf="terminatedDate.invalid">{{ terminatedDate.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>{{
                'forms.add-terminated.job.termination.terminationType' | translate
            }}</mat-label>
            <mat-select
                name="terminationReasonCode"
                required
                #terminationReasonCode="ngModel"
                [(ngModel)]="employee.terminationReasonCode"
            >
                <mat-option *ngFor="let tCode of terminationCodes | keyvalue" [value]="tCode.key">
                    <span translate="forms.add-terminated.job.termination.TERMINATION_CODES.{{ tCode.key }}"></span
                ></mat-option>
            </mat-select>
            <mat-error *ngIf="terminationReasonCode.invalid">{{ terminationReasonCode.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.job.termination.terminationReason' | translate }}</mat-label>
            <input
                matInput
                type="text"
                [(ngModel)]="employee.terminationComments"
                name="terminationComments"
                placeholder="{{ 'forms.add-terminated.job.termination.terminationReasonPlaceholder' | translate }}"
                #terminationComments="ngModel"
            />
            <mat-error *ngIf="terminationComments.invalid">{{ terminationComments.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>
</form>
