<form class="ui form" #form="ngForm" autocomplete="off">
    <app-segment-header title="{{ 'forms.employees-rehire.rehireDetails' | translate }}"></app-segment-header>
    <div class="three fields">
        <div class="field">
            <label>{{ 'forms.employees-rehire.employee' | translate }}</label>
            {{ employee.fullName }}
        </div>

        <div class="field">
            <label>{{ 'forms.employees-rehire.rehireDate' | translate }}</label>
            {{ employee.nextDayOfWorkOn | appDate }}
        </div>
    </div>

    <div style="padding: 1em"></div>

    <!--Instructions-->
    <app-segment-header title="{{ 'forms.employees-rehire.rehireProcedure' | translate }}"></app-segment-header>

    <h2 class="ui header">
        <span class="ui blue label">1</span>&nbsp;&nbsp;{{ 'forms.employees-rehire.access' | translate }}
    </h2>

    <!--rehire in past-->
    <p
        *ngIf="rehireInPast"
        style="padding-left: 3em"
        [innerHTML]="'forms.employees-rehire.reinstateImmediately' | appTranslate"
    ></p>

    <!--rehire in future-->
    <p
        *ngIf="!rehireInPast"
        style="padding-left: 3em"
        [innerHTML]="
            'forms.employees-rehire.rehireInFuture'
                | translate: { firstName: employee.firstName, date: employee.nextDayOfWorkOn | appDate }
                | sanitizeHtml
        "
    ></p>

    <h2 class="ui header">
        <span class="ui blue label">2</span>&nbsp;&nbsp;{{ 'forms.employees-rehire.benefits' | translate }}
    </h2>
    <p
        style="padding-left: 3em"
        [innerHTML]="
            'forms.employees-rehire.hasGroupBenefits' | translate: { firstName: employee.firstName } | sanitizeHtml
        "
    ></p>

    <ng-container *ngIf="!employee.isPayrollSyncEnabled">
        <h2 class="ui header">
            <span class="ui blue label">3</span>&nbsp;&nbsp;{{ 'forms.employees-rehire.payroll' | translate }}
        </h2>
        <p
            style="padding-left: 3em"
            [innerHTML]="
                'forms.employees-rehire.notOnPayRoll' | translate: { firstName: employee.firstName } | sanitizeHtml
            "
        ></p>
    </ng-container>
</form>
