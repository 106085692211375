import { Component, Input } from '@angular/core';
import { CompensationTypes } from '@app/constants/compensation-types';
import { Address } from '@app/models/employee/address.model';
import { EmploymentTypeDisplayOptions } from '@app/types/translatables/employment-type-display.options';
import { BaseForm } from '@forms/base.form';
import { Account } from '@models/account/account.model';
import { Employee } from '@models/employee/employee.model';
import { Salary } from '@models/employee/salary.model';
@Component({
    selector: 'app-forms-employees-create-terminated-review',
    templateUrl: './create-terminated-review.template.html',
    styleUrls: ['./create-terminated-review.style.scss'],
})
export class CreateTerminatedReviewForm extends BaseForm {
    @Input() employee: Employee;
    @Input() account: Account;
    @Input() salary: Salary;
    @Input() address: Address;
    @Input() canDoPayroll = false;
    @Input() terminatedOn;

    compensationTypes = CompensationTypes;
    employmentTypeDisplayOptions = EmploymentTypeDisplayOptions;
}
