<div class="ui segment">
    <form #form="ngForm" autocomplete="off">
        <ng-container *ngIf="employee && salary">
            <div class="app-grid">
                <mat-form-field class="columns-4 small-columns-11">
                    <mat-label appRemoveAriaOwns>{{
                        'forms.employees-salary.compensationType' | appTranslate
                    }}</mat-label>
                    <mat-select
                        placeholder="{{ 'forms.employees-salary.selectAType' | appTranslate }}"
                        name="compensationType"
                        required
                        placeholder="{{ 'forms.employees-salary.selectAnOption' | appTranslate }}"
                        #compensationType="ngModel"
                        [(ngModel)]="salary.frequency"
                        (ngModelChange)="handlePaymentFrequencyChanged()"
                        [disabled]="isEditMode && lastRecurringPayrollEndDate !== null"
                    >
                        <mat-option [value]="'year'">{{ 'forms.employees-salary.yearly' | appTranslate }}</mat-option>
                        <mat-option [value]="'hour'">{{ 'forms.employees-salary.hourly' | appTranslate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="compensationType.invalid">{{ compensationType.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <div class="app-grid margin-bottom-3">
                <mat-label class="columns-4 small-columns-11 margin-bottom-2">{{
                    'forms.employees-salary.compensation' | appTranslate
                }}</mat-label>
                <br />
                <mat-radio-group
                    name="modeOfCompensation"
                    [(ngModel)]="compensationMode"
                    (ngModelChange)="setCompensationName()"
                    #modeOfCompensation="ngModel"
                    [disabled]="isEditMode && lastRecurringPayrollEndDate !== null"
                    required
                >
                    <mat-radio-button
                        [checked]="mustMarkCompensationAsPrimary || salary.isPrimary"
                        [disabled]="!salary?.frequency"
                        [value]="'new'"
                        >{{ 'forms.employees-salary.regularPay' | appTranslate }}</mat-radio-button
                    >
                    <br />
                    <p class="padding-left-3">
                        {{ 'forms.employees-salary.thisIsTheDefaultRateOfPay' | appTranslate }}
                    </p>
                    <mat-radio-button
                        [disabled]="!salary?.frequency || salary?.frequency === 'year' || mustMarkCompensationAsPrimary"
                        [value]="'additional'"
                        [checked]="isEditMode && !salary.isPrimary"
                        >{{ 'forms.employees-salary.additionalCompensation' | appTranslate }}</mat-radio-button
                    >
                    <br />
                    <p class="padding-left-3">{{ 'forms.employees-salary.useThisHourlyRate' | appTranslate }}</p>
                </mat-radio-group>
            </div>
            <div class="app-grid">
                <mat-form-field class="columns-4 small-columns-11">
                    <mat-label>{{ 'forms.employees-salary.compensationName' | appTranslate }}</mat-label>
                    <input
                        matInput
                        type="string"
                        placeholder="{{ 'forms.employees-salary.enterName' | appTranslate }}"
                        name="compensationName"
                        required
                        #compensationName="ngModel"
                        [(ngModel)]="salary.name"
                        additionalCompensationNameValidator
                        [disabled]="
                            (isEditMode && compensationMode === 'additional' && lastRecurringPayrollEndDate !== null) ||
                            disableCompensationName ||
                            mustMarkCompensationAsPrimary ||
                            compensationMode === 'new'
                        "
                    />
                    <mat-error *ngIf="compensationName.invalid"> {{ compensationName.errors | formErrors }} </mat-error>
                </mat-form-field>
            </div>
            <div class="app-grid">
                <!-- Payroll Clients -->
                <mat-form-field
                    *ngIf="employee.isPayrollSyncEnabled; else nonPayrollEmployee"
                    class="columns-4 small-columns-11"
                >
                    <mat-label>{{
                        (salary.frequency === 'year' ? 'forms.employees-salary.salary' : 'forms.employees-salary.rate')
                            | appTranslate
                    }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [textMask]="{ mask: currencyMask }"
                        name="rate"
                        [min]="0"
                        #payrollSyncedRate="ngModel"
                        required
                        [ngModel]="salary.getLocalizedProperty('rate')"
                        (ngModelChange)="salary.setLocalizedProperty('rate', $event)"
                        [disabled]="isEditMode && lastRecurringPayrollEndDate !== null"
                    />
                    <span matSuffix>{{ 'forms.employees-salary.cad' | appTranslate }}</span>
                    <mat-error *ngIf="payrollSyncedRate.invalid">{{ payrollSyncedRate.errors | formErrors }}</mat-error>
                </mat-form-field>

                <!-- Non-payroll Clients -->
                <ng-template #nonPayrollEmployee>
                    <mat-form-field class="columns-4">
                        <mat-label>{{
                            (salary.frequency === 'year'
                                ? 'forms.employees-salary.salary'
                                : 'forms.employees-salary.rate'
                            ) | appTranslate
                        }}</mat-label>
                        <input
                            matInput
                            placeholder="{{ 'forms.employees-salary.egOr' | appTranslate }}"
                            name="rate"
                            [textMask]="{ mask: currencyMask }"
                            [min]="0"
                            required
                            #rate="ngModel"
                            [ngModel]="salary.getLocalizedProperty('rate')"
                            (ngModelChange)="salary.setLocalizedProperty('rate', $event)"
                            [disabled]="isEditMode && lastRecurringPayrollEndDate !== null"
                        />
                        <mat-error *ngIf="rate.invalid">{{ rate.errors | formErrors }}</mat-error>
                    </mat-form-field>

                    <mat-form-field class="columns-4 padding-left-3">
                        <mat-label appRemoveAriaOwns>{{ 'forms.employees-salary.currency' | appTranslate }}</mat-label>
                        <mat-select
                            name="currency"
                            required
                            #currency="ngModel"
                            [(ngModel)]="employee.currency"
                            [disabled]="isEditMode && salary.isPrimary && lastRecurringPayrollEndDate !== null"
                        >
                            <mat-option>{{ 'forms.employees-salary.none' | appTranslate }}</mat-option>
                            <mat-option *ngFor="let currency of currencies" [value]="currency.code"
                                >{{ currency.code }}<span *hideFrench>- {{ currency.name }}</span></mat-option
                            >
                        </mat-select>
                        <mat-error *ngIf="currency.invalid">{{ currency.errors | formErrors }}</mat-error>
                    </mat-form-field>
                </ng-template>
            </div>
            <div class="app-grid">
                <mat-form-field class="columns-4 small-columns-11">
                    <mat-label>{{ 'forms.employees-salary.hoursPerWeek' | appTranslate }}</mat-label>
                    <input
                        matInput
                        placeholder="{{ 'forms.employees-salary.enterAmount' | appTranslate }}"
                        name="hoursPerWeek"
                        required
                        #hoursPerWeek="ngModel"
                        [min]="1"
                        [ngModel]="salary.getLocalizedProperty('hoursPerWeek')"
                        (ngModelChange)="salary.setLocalizedProperty('hoursPerWeek', $event)"
                        [textMask]="{ mask: numberMask }"
                        [disabled]="isEditMode && lastRecurringPayrollEndDate !== null"
                    />
                    <mat-error *ngIf="hoursPerWeek.invalid"> {{ hoursPerWeek.errors | formErrors }} </mat-error>
                </mat-form-field>
            </div>
            <div class="app-grid">
                <mat-form-field class="columns-4 small-columns-11">
                    <mat-label>{{ 'forms.employees-salary.effectiveDate' | appTranslate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        required
                        name="effectiveAt"
                        #salaryEffectiveAt="ngModel"
                        [ngModel]="salary.effectiveAt"
                        (ngModelChange)="setFormEnabledStatus(); salary.effectiveAt = $event"
                        [matDatepicker]="effectiveAtPicker"
                    />
                    <mat-datepicker-toggle matSuffix [for]="effectiveAtPicker">
                        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #effectiveAtPicker></mat-datepicker>

                    <mat-error *ngIf="salaryEffectiveAt.invalid">{{ salaryEffectiveAt.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>

            <div class="app-grid">
                <mat-form-field class="columns-4 small-columns-11">
                    <mat-label>{{ 'forms.employees-salary.endDate' | appTranslate }}</mat-label>
                    <input
                        matInput
                        type="text"
                        [min]="salary.effectiveAt"
                        name="endsAt"
                        #salaryEndsAt="ngModel"
                        [ngModel]="salary.endsAt"
                        (ngModelChange)="salary.endsAt = $event"
                        [matDatepicker]="endsAtDatePicker"
                        [disabled]="!salary.effectiveAt"
                    />
                    <mat-hint>{{ 'forms.employees-salary.endDateHint' | translate }}</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="endsAtDatePicker">
                        <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
                    </mat-datepicker-toggle>
                    <mat-datepicker #endsAtDatePicker></mat-datepicker>

                    <mat-error *ngIf="salaryEndsAt.invalid">{{ salaryEndsAt.errors | formErrors }}</mat-error>
                </mat-form-field>
            </div>
            <ng-container *ngIf="showVacationPay && vacationPayHasLoaded">
                <div
                    class="vacation-pay"
                    *ngIf="!showVacationPayPercentage; else vacationDetails"
                    (click)="showVacationPayPercentage = true"
                >
                    {{ 'forms.employees-salary.vacationPay' | appTranslate }}
                </div>

                <ng-template #vacationDetails>
                    <div class="app-grid">
                        <div class="columns-12 vacation-pay-block">
                            <mat-form-field class="vacation-pay-form-field">
                                <mat-label>{{
                                    'forms.employees-salary.vacationPayPercentage' | appTranslate
                                }}</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    [textMask]="{ mask: percentageMask }"
                                    name="vacationPayPercentage"
                                    [ngModel]="salary.getLocalizedProperty('vacationPayPercentage')"
                                    (ngModelChange)="salary.setLocalizedProperty('vacationPayPercentage', $event)"
                                    [disabled]="isEditMode && lastRecurringPayrollEndDate !== null"
                                />
                                <mat-hint>{{
                                    'forms.employees-salary.percentageOfGrossPayPerPeriod' | appTranslate
                                }}</mat-hint>
                            </mat-form-field>
                            <ui-button
                                class="columns-4 vacation-pay remove margin-left-2"
                                [disabled]="
                                    salary.vacationPayPercentage === null ||
                                    (salary.vacationPayPercentage === undefined && lastRecurringPayrollEndDate !== null)
                                "
                                (click)="onRemoveVacationPay()"
                                >{{ 'forms.employees-salary.xRemove' | appTranslate }}
                            </ui-button>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </ng-container>
    </form>
</div>
