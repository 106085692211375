<form class="loadable" #form="ngForm" autocomplete="off" [class.isLoading]="isLoading">
    <p
        [innerHTML]="
            {
                key: 'forms.applicant-tracker.review.youCanChangeYourDefaultOptionsIn',
                params: { link: onboardingSettingsURL },
            } | appTranslate
        "
    ></p>
    <div class="responsive-grid margin-top-4">
        <div class="row">
            <!-- Collect Onboarding Questions? -->
            @if (hasOnboardingQuestions) {
                <div class="radio-group">
                    <mat-label id="collectHiringQuestions">{{
                        'forms.applicant-tracker.review.sendHiringQuestionnaire' | appTranslate
                    }}</mat-label>
                    <mat-radio-group
                        aria-labelledby="collectHiringQuestions"
                        name="collectHiringQuestions"
                        required
                        [disabled]="!hasOnboardingQuestions"
                        [value]="!!employee.collectHiringQuestions"
                        (change)="employee.collectHiringQuestions = $event.value"
                    >
                        <mat-radio-button [value]="true">{{ 'yes' | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
            } @else {
                <h3
                    [innerHTML]="
                        'forms.applicant-tracker.review.noOnboardingQuestionsExistToAddOnboardingQuestionsVisitThe'
                            | appTranslate
                    "
                ></h3>
            }
            @if (!selfServeFeatureFlag) {
                <div class="radio-group">
                    <mat-label id="sendCustomWelcomeMessage">{{
                        'forms.applicant-tracker.review.sendCustomWelcomeMessage' | translate
                    }}</mat-label>
                    <mat-radio-group
                        aria-labelledby="sendCustomWelcomeMessage"
                        name="sendCustomWelcomeMessage"
                        [(ngModel)]="customWelcomeMessage"
                        (ngModelChange)="setEmployeeWelcomeMessage($event)"
                    >
                        <mat-radio-button [value]="true">{{ 'yes' | translate }}</mat-radio-button>
                        <mat-radio-button [value]="false">{{ 'no' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
            }
        </div>
    </div>

    @if (customWelcomeMessage) {
        <mat-form-field class="margin-top-2 width-full">
            <mat-label>{{ 'forms.applicant-tracker.review.customWelcomeMessage' | translate }}</mat-label>
            <ui-rich-text
                name="customWelcomeMessageInput"
                #customWelcomeMessageInput="ngModel"
                [(ngModel)]="employee.welcomeMessage"
            ></ui-rich-text>
        </mat-form-field>
    }

    @if (onboardingVideo) {
        <app-segment-header
            title="forms.applicant-tracker.review.onboardingVideo"
            class="margin-bottom-1"
        ></app-segment-header>
        <app-video-lookup [videoUrl]="defaultOnboardingVideo?.url" (metaChange)="handleOnboardingVideoChange($event)">
            <div class="info">
                <mat-icon svgIcon="info"></mat-icon
                >{{
                    'forms.applicant-tracker.review.thisVideoCannotBeChangedOrRemovedOnceTheOnboardinglinkHasBeenSen'
                        | translate
                }}
            </div>
        </app-video-lookup>
    }

    <!-- Account Information -->
    <app-segment-header title="forms.applicant-tracker.review.accountInformation"></app-segment-header>
    <div class="responsive-grid">
        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.firstName' | appTranslate }}</label>
                {{ account.legalFirstName }}
            </div>
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.lastName' | appTranslate }}</label>
                {{ account.legalLastName }}
            </div>
        </div>
        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.email' | appTranslate }}</label>
                {{ account.email }}
            </div>

            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.officeName' | appTranslate }}</label>
                {{ employee.office?.name }}
            </div>
        </div>
        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.hireDate' | appTranslate }}</label>
                {{ employee.hiredAt | appDate: 'yyyy-MM-dd' }}
            </div>

            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.additionalTerms' | appTranslate }}</label>
                {{ employee.additionalTerms }}
            </div>
        </div>
    </div>

    <!-- Compensation -->
    <app-segment-header title="forms.applicant-tracker.review.compensation"></app-segment-header>
    <div class="responsive-grid">
        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.compensationType' | appTranslate }}</label>
                {{
                    (salary.frequency === 'year'
                        ? 'applicant-tracker.compensationTypes.yearly'
                        : 'applicant-tracker.compensationTypes.hours'
                    ) | appTranslate
                }}
            </div>
            <div class="vertical-label">
                <label>{{
                    (salary.frequency === 'year'
                        ? 'applicant-tracker.compensationTypes.salary'
                        : 'applicant-tracker.compensationTypes.rate'
                    ) | appTranslate
                }}</label>
                {{ salary.rate }}
            </div>
        </div>

        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.employmentType' | appTranslate }}</label>
                {{ employee.employmentType }}
            </div>

            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.hoursPerWeek' | appTranslate }}</label>
                {{ employee.hoursPerWeek }}
            </div>
        </div>
        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.stockOptions' | appTranslate }}</label>
                {{ employee.stockOptions }}
            </div>
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.vacationPayPercentage' | appTranslate }}</label>
                {{ salary.getLocalizedProperty('vacationPayPercentage') }}
            </div>
        </div>
    </div>

    <!-- Employee's Role -->
    <app-segment-header title="forms.applicant-tracker.review.employeesRole"></app-segment-header>
    <div class="responsive-grid">
        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.department' | appTranslate }}</label>
                {{ employee.department?.name }}
            </div>
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.reportsTo' | appTranslate }}</label>
                {{ employee.manager?.fullName || 'None' }}
            </div>
        </div>
        <div class="row">
            <div class="vertical-label">
                <label>{{ 'forms.applicant-tracker.review.position' | appTranslate }}</label>
                {{ employee.job?.title }}
            </div>
        </div>
    </div>

    <ng-container *ngIf="employee.isPayrollSyncEnabled">
        <app-segment-header title="forms.applicant-tracker.review.humiPayroll"></app-segment-header>
        <div class="responsive-grid">
            <div class="row">
                <div class="vertical-label">
                    <label>{{ 'forms.applicant-tracker.review.automaticallySyncedWithPayroll' | appTranslate }}</label>
                    <label class="no-margin-bottom sinRequired">{{
                        'forms.applicant-tracker.review.sinRequiredDuringOnboarding' | appTranslate
                    }}</label>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!employee.isPayrollSyncEnabled && employee.isSinRequired">
        <app-segment-header title="forms.applicant-tracker.review.employeeSin"></app-segment-header>
        <div class="responsive-grid">
            <div class="row">
                <div class="vertical-label">
                    <label class="no-margin-bottom sinRequired">{{
                        'forms.applicant-tracker.review.required' | appTranslate
                    }}</label>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- Documents -->
    <ng-container *ngIf="documentAssignments.length">
        <app-segment-header title="forms.applicant-tracker.review.documents"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let documentAssignment of documentAssignments"
                [removable]="false"
                [label]="documentAssignment.companyDocument.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Custom Fields -->
    <ng-container *ngIf="customFields.length">
        <app-segment-header title="forms.applicant-tracker.review.customFields"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let customField of customFields"
                [removable]="false"
                [label]="customField.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Time Off Policies -->
    <ng-container *ngIf="selectedTimeOffPolicies.length">
        <app-segment-header title="forms.applicant-tracker.onboarding.timeOffPolicies"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let policy of selectedTimeOffPolicies"
                [removable]="false"
                [label]="policy.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Time tracking -->
    <ng-container *ngIf="showTimeTracking">
        <app-segment-header title="time-tracking.name"></app-segment-header>
        <div class="vertical-label">
            <label>{{ 'time-tracking.hiring.enabled' | appTranslate }}</label>
            {{ (addToTimeTracking ? 'Yes' : 'No') | appTranslate }}
        </div>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let project of timeTrackingProjects"
                [removable]="false"
                [label]="project.name"
            ></app-common-label-item>
        </div>
        <div *ngIf="timeTrackingPayrollIntegrationOnForCompany && addToTimeTracking" class="vertical-label">
            <label>{{ 'time-tracking.hiring.transferHoursToPayroll' | appTranslate }}</label>
            {{ (addToTimeTrackingPayrollIntegration ? 'Yes' : 'No') | appTranslate }}
        </div>
    </ng-container>

    <!-- Roles -->
    <ng-container *ngIf="roles.length">
        <app-segment-header title="forms.applicant-tracker.review.roles"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let role of roles"
                [removable]="false"
                [label]="role.name | appTranslateOptions: roleDisplay"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Training Programs -->
    <ng-container *ngIf="trainingPrograms.length">
        <app-segment-header title="forms.applicant-tracker.review.trainingPrograms"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let trainingProgram of trainingPrograms"
                [removable]="false"
                [label]="trainingProgram.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Onboarding Reminders -->
    <ng-container *ngIf="onboardingReminders.length">
        <app-segment-header title="forms.applicant-tracker.review.onboardingReminders"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                *ngFor="let reminder of onboardingReminders"
                [removable]="false"
                [label]="reminder.name"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Work Schedule -->
    <ng-container *ngIf="workSchedule">
        <app-segment-header title="forms.applicant-tracker.review.workSchedule"></app-segment-header>
        <div class="item-list">
            <app-common-label-item
                [removable]="false"
                [label]="workSchedule.name | appTranslateOptions: workScheduleDisplay"
            ></app-common-label-item>
        </div>
    </ng-container>

    <!-- Benefits -->
    <ng-container *ngIf="selectedBenefitLabel">
        <app-segment-header title="forms.applicant-tracker.review.benefits"></app-segment-header>
        <div class="item-list">
            <app-common-label-item [removable]="false" [label]="selectedBenefitLabel"></app-common-label-item>
        </div>
    </ng-container>
</form>
