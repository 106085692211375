<form class="ui form" #form="ngForm">
    <!-- Personal Info -->
    <h2>{{ 'forms.add-terminated.personal.stepTitle' | translate }}</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.basics.firstName' | translate }}</label>
            <div>{{ employee.firstName }}</div>
        </div>
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.basics.lastName' | translate }}</label>
            <div>{{ employee.lastName }}</div>
        </div>
    </div>

    <div class="three fields">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.basics.email' | translate }}</label>
            <div>{{ account.email }}</div>
        </div>
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.basics.dateOfBirth' | translate }}</label>
            <div>{{ employee.bornOn | appDate: 'yyyy-MM-dd' }}</div>
        </div>
    </div>

    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.basics.SIN' | translate }}</label>
            <div>{{ employee.sin }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Address -->
    <h2 class="margin-top-3">{{ 'forms.add-terminated.personal.address.title' | translate }}</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.address.streetAddress' | translate }}</label>
            <div>{{ address.addressLine1 }}</div>
        </div>
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.address.country' | translate }}</label>
            <div>{{ address.country | i18nCountry }}</div>
        </div>
    </div>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.address.postalCode' | translate }}</label>
            <div>{{ address.postalCode }}</div>
        </div>
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.address.city' | translate }}</label>
            <div>{{ address.city }}</div>
        </div>
    </div>
    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.personal.address.province' | translate }}</label>
            <div>{{ address.province }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Job -->
    <h2 class="margin-top-3">{{ 'forms.add-terminated.job.stepTitle' | translate }}</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.job.employment.employmentType' | translate }}</label>
            <div>{{ employee.employmentType | appTranslateOptions: employmentTypeDisplayOptions }}</div>
        </div>
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.job.employment.hireDate' | translate }}</label>
            <div>{{ employee.hiredAt | appDate: 'yyyy-MM-dd' }}</div>
        </div>
    </div>
    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.job.employment.office' | translate }}</label>
            <div>{{ employee?.office?.name }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Termination -->
    <h2 class="margin-top-3">{{ 'forms.add-terminated.job.termination.title' | translate }}</h2>
    <div class="three fields">
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.job.termination.terminationDate' | translate }}</label>
            <div>{{ terminatedOn | appDate: 'yyyy-MM-dd' }}</div>
        </div>
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.job.termination.terminationType' | translate }}</label>
            <div
                translate="forms.add-terminated.job.termination.TERMINATION_CODES.{{ employee.terminationReasonCode }}"
            ></div>
        </div>
    </div>
    <div class="three fields margin-bottom-3">
        <div class="field">
            <label class="font-medium">{{
                'forms.add-terminated.job.termination.terminationReason' | translate
            }}</label>
            <div>{{ employee.terminationComments }}</div>
        </div>
    </div>

    <mat-divider></mat-divider>

    <!-- Payment -->
    <h2 class="margin-top-3">{{ 'forms.add-terminated.payroll.stepTitle' | translate }}</h2>
    <div class="three fields">
        <div *ngIf="compensationTypes.hasOwnProperty(this.salary.frequency)" class="field">
            <label class="font-medium">{{ 'forms.add-terminated.payroll.compensationType' | translate }}</label>
            <div>{{ salary.frequencyLabel | translate }}</div>
        </div>
        <div *ngIf="!!salary.rate" class="field">
            <label class="font-medium">{{ salary.frequencyLabel | translate }}</label>
            <div>{{ salary.rate | i18nNum: '$' }}</div>
        </div>
    </div>
    <div class="three fields">
        <div *ngIf="!!salary.effectiveAt" class="field">
            <label class="font-medium">{{
                'forms.add-terminated.payroll.salaryEffectiveDate'
                    | translate: { frequency: salary.frequencyLabel | translate }
            }}</label>
            <div>{{ salary.effectiveAt | appDate: 'yyyy-MM-dd' }}</div>
        </div>
        <div class="field">
            <label class="font-medium">{{ 'forms.add-terminated.payroll.currency' | translate }}</label>
            <div>{{ employee.currency }}</div>
        </div>
    </div>
    <div class="three fields">
        <div *ngIf="!!salary.hoursPerWeek" class="field">
            <label class="font-medium">{{ 'forms.add-terminated.payroll.hoursPerWeek' | translate }}</label>
            <div>{{ salary.hoursPerWeek }}</div>
        </div>
    </div>

    <!-- Optional -->
    <ng-container *ngIf="canDoPayroll">
        <mat-divider></mat-divider>
        <h2 class="margin-top-3">{{ 'forms.add-terminated.payroll.humiPayroll' | translate }}</h2>

        <div class="three fields">
            <div class="field">
                <label> {{ 'forms.add-terminated.payroll.autoSync' | translate }}</label>
                <div>{{ (employee.isPayrollSyncEnabled ? 'yes' : 'no') | translate }}</div>
            </div>
        </div>
    </ng-container>
</form>
