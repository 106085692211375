<form *ngIf="!isReseting" #form="ngForm" autocomplete="off">
    <div class="responsive-grid">
        <mat-form-field>
            <mat-label appRemoveAriaOwns>{{ 'employees.jobAndPay.incomeType' | translate }}</mat-label>
            <mat-select
                placeholder="{{ 'employees.jobAndPay.selectAType' | translate }}"
                name="selectedCompanyAdditionalIncome"
                required
                #selectedCompanyAdditionalIncome="ngModel"
                [(ngModel)]="companyAdditionalIncome"
            >
                <mat-option *ngFor="let additionalIncome of companyAdditionalIncomes" [value]="additionalIncome">{{
                    additionalIncome.displayName
                }}</mat-option>
            </mat-select>
            <mat-error *ngIf="selectedCompanyAdditionalIncome.invalid">{{
                selectedCompanyAdditionalIncome.errors | formErrors
            }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="companyAdditionalIncome">
            <mat-label>{{ 'employees.jobAndPay.recurringAmountPerPeriod' | translate }}</mat-label>
            <input
                matInput
                name="amount"
                [ngModel]="companyAdditionalIncome.employeeAdditionalIncomes[0].getLocalizedProperty('amount')"
                (ngModelChange)="
                    companyAdditionalIncome.employeeAdditionalIncomes[0].setLocalizedProperty('amount', $event)
                "
                [textMask]="{ mask: currencyMask }"
                #amount="ngModel"
                [min]="0.01"
            />
            <mat-error *ngIf="amount.invalid">{{ amount.errors | formErrors }}</mat-error>
        </mat-form-field>

        <!-- Disabled input to show while no additional income type selected -->
        <mat-form-field *ngIf="!companyAdditionalIncome">
            <mat-label>{{ 'employees.jobAndPay.recurringAmountPerPeriod' | translate }}</mat-label>
            <mat-icon svgIcon="dollar" matPrefix></mat-icon>
            <input matInput name="amount-disabled" [disabled]="true" />
        </mat-form-field>
    </div>
</form>
