<form #form="ngForm">
    <ng-container *ngIf="reminder">
        <!-- Name/details -->
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'employees.onboardingSettings.name' | translate }}</mat-label>
                <input matInput type="text" required [(ngModel)]="reminder.name" #name="ngModel" name="name" />
                <mat-error *ngIf="name.invalid">{{ name.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-6 small-columns-12">
                <mat-label>{{ 'employees.onboardingSettings.details' | translate }}</mat-label>
                <input matInput type="text" [(ngModel)]="reminder.body" name="body" />
            </mat-form-field>
        </div>

        <!-- Send To -->
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label appRemoveAriaOwns>{{ 'employees.onboardingSettings.sendTo' | translate }}</mat-label>
                <mat-select
                    required
                    [ngModel]="remindToType"
                    (ngModelChange)="setRemindToType($event)"
                    #remindTo="ngModel"
                    name="remindTo"
                    placeholder="{{ 'employees.onboardingSettings.selectAnOption' | translate }}"
                >
                    <mat-option value="manager">{{ 'employees.onboardingSettings.manager' | translate }}</mat-option>
                    <mat-option value="employee">{{ 'employees.onboardingSettings.employee' | translate }}</mat-option>
                    <mat-option value="administrator">{{
                        'employees.onboardingSettings.administrator' | translate
                    }}</mat-option>
                    <mat-option value="colleague">{{
                        'employees.onboardingSettings.colleague' | translate
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="remindTo.invalid">{{ remindTo.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="remindToType === 'colleague'" class="columns-6 small-columns-12">
                <mat-label>{{ 'employees.onboardingSettings.selectColleague' | translate }}</mat-label>
                <ui-employee-autocomplete
                    [ngModel]="employee"
                    (ngModelChange)="setColleague($event)"
                    required
                    name="colleague"
                    #colleague="ngModel"
                ></ui-employee-autocomplete>
                <mat-error *ngIf="colleague.invalid">{{ colleague.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>

        <!-- When to Send -->
        <div class="app-grid">
            <mat-form-field class="columns-6 small-columns-12">
                <mat-label appRemoveAriaOwns>{{ 'employees.onboardingSettings.whenToSend' | translate }}</mat-label>
                <mat-select
                    [(ngModel)]="remindBefore"
                    (ngModelChange)="setRemindBefore(remindBefore)"
                    name="remindBefore"
                    required
                    #remindBeforeInput="ngModel"
                >
                    <mat-option value="on">{{ 'employees.onboardingSettings.onHireDate' | translate }}</mat-option>
                    <mat-option value="before">{{
                        'employees.onboardingSettings.beforeHireDate' | translate
                    }}</mat-option>
                    <mat-option value="after">{{
                        'employees.onboardingSettings.afterHireDate' | translate
                    }}</mat-option>
                </mat-select>
                <mat-error *ngIf="remindBeforeInput">{{ remindBeforeInput.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="remindBefore !== 'on'" class="columns-6 small-columns-12">
                <mat-label
                    ><span
                        translate="employees.onboardingSettings.days{{
                            remindBefore === 'before' ? 'Before' : 'After'
                        }}FirstDay"
                    ></span
                ></mat-label>
                <input
                    matInput
                    type="number"
                    required
                    min="0"
                    [(ngModel)]="reminder.delayDays"
                    #days="ngModel"
                    name="delayDays"
                />
                <mat-error *ngIf="days.invalid">{{ days.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
