import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Platform } from '@app/classes';
import { CompensationTypes } from '@app/constants/compensation-types';
import { EmploymentTypes } from '@app/constants/employment-types';
import { isSameModel, makePercentageMask } from '@app/functions';
import { OfficeForm, PositionForm } from '@app/modules/forms/company/components';
import { DepartmentForm } from '@app/modules/forms/employees/components/department/department.form';
import { AbilityService, AuthService } from '@app/services';
import { Locale, Translatable } from '@app/types/translatable.type';
import { CompensationTypeDisplayOptions } from '@app/types/translatables/applicant-tracker/compensation-type-display.options';
import { EmploymentTypeDisplayOptions } from '@app/types/translatables/employment-type-display.options';
import { BaseForm } from '@forms/base.form';
import { Account } from '@models/account/account.model';
import { Role } from '@models/account/role.model';
import { Department } from '@models/company/department.model';
import { Job } from '@models/company/job.model';
import { Office } from '@models/company/office.model';
import { Employee } from '@models/employee/employee.model';
import { EmploymentType } from '@models/employee/employment-type.model';
import { Salary } from '@models/employee/salary.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-employees-form-hire-information',
    templateUrl: './information.form.html',
    styleUrls: ['./information.style.scss'],
})
export class InformationForm extends BaseForm implements OnInit {
    @Input() account: Account;
    @Input() employee: Employee;
    @Input() salary: Salary;

    offices: Office[] = [];
    departments: Department[] = [];
    jobs: Job[] = [];
    roles: Role[] = [];
    employmentTypeValues: string[] = EmploymentTypes;
    employmentTypes: EmploymentType[] = [];
    compensationTypes = CompensationTypes;

    viewPayrollPermission: string[] = Platform.payroll.permission.view;
    createOfficePermission = Office.permission.edit;
    createDepartmentPermission = Department.permission.edit;
    createPositionPermission = Job.permission.edit;
    canManageAll = false;
    payrollEnabled = false;

    compareWith = isSameModel;
    percentageMask = makePercentageMask(this.translateService.currentLang as Locale);

    isLoading = false;

    openCandidateEmailValidationModal = false;
    candidateId: number | null;

    candidateEmailValidationModalDescription: Translatable = '';
    candidateEmailValidationModalPrimaryButtonLabel = 'applicant-tracker.viewOffer';

    employmentTypeDisplay = EmploymentTypeDisplayOptions;
    compensationTypeDisplay = CompensationTypeDisplayOptions;

    constructor(
        private auth: AuthService,
        private abilities: AbilityService,
        private router: Router,
        private translateService: TranslateService
    ) {
        super();
        this.payrollEnabled = this.abilities.payroll();
        this.canManageAll = this.auth.can('manageAll');
        this.getDropdownData();
    }

    async ngOnInit(): Promise<void> {
        this.employee.isSinRequired = true;

        if (this.payrollEnabled) {
            this.employee.isPayrollSyncEnabled = false;
            this.employee.isSinRequired = false;
        }

        // If a user that cannot manage all is hiring someone, we can only allow the
        // manager for the new employee to be the current user so that the user does
        // not lose access to modify this new hire.
        if (!this.canManageAll) {
            this.employee.manager = this.auth.employee;
        }
        this.setDefaultHoursPerWeek();
    }

    removeWhiteSpacesOnEmail(email: string): void {
        this.account.email = email.replace(/\s/g, '');
    }

    onBlur(): void {
        const emailFormControl = this.form.form.controls['email'];

        if (!emailFormControl.errors) {
            return;
        }

        this.showCandidateEmailValidationModal(emailFormControl);
    }

    setCandidateId(candidateId: number): void {
        this.candidateId = candidateId;
    }

    onSubmit(): void {
        const emailFormControl = this.form.form.controls['email'];
        if (!emailFormControl.errors) {
            return;
        }

        this.showCandidateEmailValidationModal(emailFormControl);
    }

    onCloseCandidateEmailValidationModal(): void {
        // Reset email field
        this.account.email = '';
    }

    onViewCandidate(): void {
        if (this.candidateId) {
            this.router.navigate(['/', 'employees', 'offer-letters', 'candidates', this.candidateId]);
        }

        this.router.navigate(['/', 'employees', 'offer-letters', 'candidates']);
    }

    setDefaultHoursPerWeek(): void {
        this.salary.hoursPerWeek = 40;
    }

    onEmploymentTypeChange($event: MatSelectChange): void {
        this.employee.employmentTypeId = this.employmentTypes.find((type) => type.name === $event.value)?.id;
    }

    async createAndSetOffice(form: OfficeForm): Promise<void> {
        const office: Office = await form.office.param('company', this.auth.company.id).save();
        this.employee.office = office;
        this.offices.push(office);
        this.offices.sort((a: Office, b: Office) => a.name.localeCompare(b.name));
        form.reset();
    }

    async createAndSetPosition(form: PositionForm): Promise<void> {
        const position: Job = await form.position.param('company', this.auth.company.id).save();
        this.employee.job = position;
        this.jobs.push(position);
        this.jobs.sort((a: Job, b: Job) => a.title.localeCompare(b.title));
        form.reset();
    }

    async createAndSetDepartment(form: DepartmentForm): Promise<void> {
        const department: Department = await form.department.param('company', this.auth.company.id).save();
        this.employee.department = department;
        this.departments.push(department);
        this.departments.sort((a: Department, b: Department) => a.name.localeCompare(b.name));
        form.reset();
    }

    private async getDropdownData(): Promise<void> {
        await Promise.all([this.getOffices(), this.getDepartments(), this.getPositions(), this.getEmploymentTypes()]);
    }

    private async getEmploymentTypes(): Promise<void> {
        const [employmentTypes] = await EmploymentType.param('company', this.auth.company.id)
            .orderBy('id', 'ASC')
            .all();
        this.employmentTypes = employmentTypes;
    }

    private async getOffices(): Promise<void> {
        const [offices] = await Office.param('company', this.auth.company.id).all();
        this.offices = offices;
    }

    private async getDepartments(): Promise<void> {
        const [departments] = await Department.param('company', this.auth.company.id).orderBy('name', 'ASC').all();
        this.departments = departments;
    }

    private async getPositions(): Promise<void> {
        const [jobs] = await Job.param('company', this.auth.company.id).orderBy('title', 'ASC').all();
        this.jobs = jobs;
    }

    private showCandidateEmailValidationModal(emailFormControl: AbstractControl): void {
        // If there is only one candidate with same email address
        if (emailFormControl.hasError('belongsToAnExistingCandidate')) {
            this.candidateEmailValidationModalDescription = 'applicant-tracker.thereIsAPendingOfferWithThisEmail';
            this.openCandidateEmailValidationModal = true;
        }

        // If there are multiple candidates with same email address
        if (emailFormControl.hasError('belongsToMultipleExistingCandidates')) {
            this.candidateEmailValidationModalPrimaryButtonLabel = 'View offers';
            this.candidateEmailValidationModalDescription =
                'applicant-tracker.thereAreMultiplePendingOffersWithThisEmail';

            this.openCandidateEmailValidationModal = true;
        }
    }
}
