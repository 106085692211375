<form #form="ngForm">
    <!-- Basic -->
    <h2 class="ui header">{{ 'forms.add-terminated.personal.basics.title' | translate }}</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.basics.firstName' | translate }}</mat-label>
            <input
                matInput
                type="text"
                required
                placeholder="{{ 'forms.add-terminated.personal.basics.firstNamePlaceholder' | translate }}"
                name="firstName"
                [(ngModel)]="employee.firstName"
                #firstName="ngModel"
            />
            <mat-error *ngIf="firstName.invalid">{{ firstName.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.basics.lastName' | translate }}</mat-label>
            <input
                matInput
                type="text"
                name="lastName"
                placeholder="{{ 'forms.add-terminated.personal.basics.lastNamePlaceholder' | translate }}"
                [(ngModel)]="employee.lastName"
                #lastName="ngModel"
                required
            />
            <mat-error *ngIf="lastName.invalid">{{ lastName.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.basics.email' | translate }}</mat-label>
            <input
                matInput
                type="text"
                name="email"
                placeholder="{{ 'forms.add-terminated.personal.basics.emailPlaceholder' | translate }}"
                #email="ngModel"
                [(ngModel)]="account.email"
                required
                emailValidator
            />
            <mat-error *ngIf="email.invalid">{{ email.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.basics.dateOfBirth' | translate }}</mat-label>
            <input
                matInput
                placeholder="{{ 'forms.add-terminated.personal.basics.dateOfBirthPlaceholder' | translate }}"
                required
                #dateOfBirth="ngModel"
                name="bornAt"
                [(ngModel)]="employee.bornOn"
                [matDatepicker]="dateOfBirthPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dateOfBirthPicker></mat-datepicker>

            <mat-error *ngIf="dateOfBirth.invalid">{{ dateOfBirth.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.basics.SIN' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.add-terminated.personal.basics.SINPlaceholder' | translate }}"
                #sin="ngModel"
                maxlength="9"
                pattern=".{9,9}"
                required
                name="sin"
                [(ngModel)]="employee.sin"
            />
            <mat-error *ngIf="sin.invalid">{{ sin.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <!-- Address -->
    <h2 class="ui header">{{ 'forms.add-terminated.personal.address.title' | translate }}</h2>
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.address.streetAddress' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.add-terminated.personal.address.streetAddressPlaceholder' | translate }}"
                required
                #addressLine1="ngModel"
                name="addressLine1"
                [(ngModel)]="address.addressLine1"
            />
            <mat-error *ngIf="addressLine1.invalid">{{ addressLine1.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.address.country' | translate }}</mat-label>
            <ui-country-autocomplete
                placeholder="{{ 'forms.add-terminated.personal.address.countryPlaceholder' | translate }}"
                name="countryName"
                #country="ngModel"
                required
                [(ngModel)]="address.country"
            ></ui-country-autocomplete>

            <mat-error *ngIf="country.invalid">{{ country.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.address.province' | translate }}</mat-label>
            <ui-state-autocomplete
                placeholder="{{ 'forms.add-terminated.personal.address.provincePlaceholder' | translate }}"
                name="provinceName"
                #province="ngModel"
                required
                [country]="address.country"
                [(ngModel)]="address.province"
            ></ui-state-autocomplete>

            <mat-error *ngIf="province.invalid">{{ province.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.personal.address.city' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.add-terminated.personal.address.cityPlaceholder' | translate }}"
                required
                #city="ngModel"
                name="city"
                [(ngModel)]="address.city"
            />
            <mat-error *ngIf="city.invalid">{{ city.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <ng-container *ngIf="address.country === 'Canada'">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.add-terminated.personal.address.postalCode' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="A1B 2C3"
                    required
                    #postalCode="ngModel"
                    name="postalCode"
                    [textMask]="postalCodeMask"
                    [(ngModel)]="address.postalCode"
                />
                <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
            </mat-form-field>
        </ng-container>
        <ng-container *ngIf="address.country !== 'Canada'">
            <mat-form-field class="columns-4 small-columns-12">
                <mat-label>{{ 'forms.add-terminated.personal.address.zipCode' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'forms.add-terminated.personal.address.zipCodePlaceholder' | translate }}"
                    required
                    #postalCode="ngModel"
                    name="postalCode"
                    [(ngModel)]="address.postalCode"
                />
                <mat-error *ngIf="postalCode.invalid">{{ postalCode.errors | formErrors }}</mat-error>
            </mat-form-field>
        </ng-container>
    </div>
</form>
