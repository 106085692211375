import { Component } from '@angular/core';
import { makeCurrencyMask } from '@app/functions';
import { CompanyAdditionalIncome } from '@app/models/payroll/company-additional-income.model';
import { Locale } from '@app/types/translatable.type';
import { BaseForm } from '@forms/base.form';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-form-add-additional-income',
    templateUrl: './add-additional-income.template.html',
})
export class AddAdditionalIncomeForm extends BaseForm {
    companyAdditionalIncomes: CompanyAdditionalIncome[] = [];
    companyAdditionalIncome: CompanyAdditionalIncome | null = null;
    isReseting = false;

    currencyMask = makeCurrencyMask({}, this.translateService.currentLang as Locale);

    constructor(private translateService: TranslateService) {
        super();
    }

    reset(): void {
        this.companyAdditionalIncome = null;
        this.isReseting = true;
        setTimeout(() => (this.isReseting = false), 0);
        super.reset();
    }
}
