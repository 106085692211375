import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { ErrorParser } from '@app/classes';
import { makeCurrencyMask, makeCustomDecimalNumberMask, makePercentageMask } from '@app/functions';
import { PendingPayrollStatus } from '@app/interfaces/pending-payroll-status.interface';
import { Currency } from '@app/models/common/currency.model';
import { CompensationService } from '@app/modules/employees/services';
import { AuthService, CurrencyService, NotifyService, PayrollService } from '@app/services';
import { Locale } from '@app/types/translatable.type';
import { BaseForm } from '@forms/base.form';
import { Employee } from '@models/employee/employee.model';
import { Salary } from '@models/employee/salary.model';
import { TranslateService } from '@ngx-translate/core';
import { parse } from 'date-fns';
import moment from 'moment';
import { CompensationMode } from '../compensation-mode.enum';

@Component({
    selector: 'app-primary-compensation-form',
    templateUrl: './primary-compensation.form.template.html',
    styleUrls: ['./primary-compensation.form.styles.scss'],
})
export class PrimaryCompensationForm extends BaseForm implements OnInit, OnChanges {
    @ViewChild('form') form;
    @Input() employee: Employee;
    @Input() salary: Salary;
    @Input() isEditMode = false;
    @Input() lastPaidRecurringPayrollEndDate: Date = null;
    @Output() disabledSaveButtonEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() displayVacationPayInCompensationHistoryTable: EventEmitter<boolean> = new EventEmitter<boolean>();
    disableSaveButton = false;
    pendingPayrollEndDate: Date | null = null;
    isPayrollAdmin = false;
    currencies: Currency[] = [];

    currencyMask = makeCurrencyMask(
        {
            allowDecimal: true,
        },
        this.translateService.currentLang as Locale
    );
    percentageMask = makePercentageMask(this.translateService.currentLang as Locale);
    numberMask = makeCustomDecimalNumberMask(this.translateService.currentLang as Locale);

    showVacationPayPercentage = false;
    compensationMode: string;
    disableCompensationName = false;
    vacationPayHasLoaded = false;
    compensationIntegratedWithTimeOff: boolean = null;
    allEmployeePrimaryCompensations: Salary[] = null;
    lastRecurringPayrollEndDate: Date | null;
    employeeCurrentPrimaryCompensations: Salary[];
    disableFormDueToRegularPayEndDate = false;
    mustMarkCompensationAsPrimary = false;

    get showVacationPay(): boolean {
        return this.employee.is(this.auth.employee) || this.auth.isAdmin();
    }

    constructor(
        private auth: AuthService,
        private payrollService: PayrollService,
        private notify: NotifyService,
        private currencyService: CurrencyService,
        private compensationService: CompensationService,
        private translateService: TranslateService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.isPayrollAdmin = this.auth.can('accessPayroll');
        this.salary.hoursPerWeek = this.isEditMode ? this.salary.hoursPerWeek : 40;
        if (this.isEditMode) {
            this.compensationMode = this.salary?.isPrimary ? CompensationMode.new : CompensationMode.additional;
        }
        try {
            this.employeeCurrentPrimaryCompensations = await this.getEmployeePrimaryCompensations();
            this.currencies = await this.currencyService.getAllCurrencies();
            this.pendingPayrollEndDate = this.employee.isPayrollSyncEnabled
                ? await this.checkPendingPayrollStatus()
                : null;
            this.lastRecurringPayrollEndDate = this.employee.isPayrollSyncEnabled
                ? await this.payrollService.getLastRecurringPayrollEndDate(this.auth.company.id)
                : null;
            this.allEmployeePrimaryCompensations = await this.getAllEmployeePrimaryCompensations();
        } catch (error) {
            this.notify.error(ErrorParser.parse(error));
        }
        this.setCompensationTableEmitter();
        this.vacationPayHasLoaded = true;
    }

    setCompensationTableEmitter(): void {
        this.displayVacationPayInCompensationHistoryTable.emit(true);
    }

    async getAllEmployeePrimaryCompensations(): Promise<Salary[]> {
        const [allEmployeePrimaryCompensations] = await Salary.param('company', this.auth.company.id)
            .param('employee', this.employee.id)
            .where('isPrimary', true)
            .get();
        return allEmployeePrimaryCompensations;
    }

    async getEmployeePrimaryCompensations(): Promise<Salary[]> {
        const [employeeCurrentPrimaryCompensations] = await Salary.param('company', this.auth.company.id)
            .param('employee', this.employee.id)
            .where('current', true)
            .where('isPrimary', true)
            .get();
        return employeeCurrentPrimaryCompensations;
    }

    ngOnChanges(): void {
        if (this.isEditMode && this.salary) {
            this.showVacationPayPercentage = this.shouldVacationPayBeDisplayed(this.isEditMode, this.salary);
        }
        if (this.employee.isPayrollSyncEnabled) {
            this.employee.currency = 'CAD';
        }
    }

    setCompensationAsPrimary(): void {
        this.mustMarkCompensationAsPrimary = true;
        this.compensationMode = CompensationMode.new;
        this.setCompensationName();
    }

    async handlePaymentFrequencyChanged(): Promise<void> {
        this.disableFormDueToRegularPayEndDate = false;
        if (this.salary.frequency === 'year') {
            this.compensationMode = CompensationMode.new;
            this.setCompensationAsPrimary();
        }
        if (this.salary.frequency === 'hour') {
            await this.determineFormEditableState();
        }
    }

    determineIfThereIsAPrimaryCompensationInTheFuture(employeeCompensations: Salary[]): boolean {
        const futurePrimaryCompensationsemployeeCompensations = employeeCompensations.filter((compensation: Salary) => {
            const isEffectiveAtInTheFuture = moment(compensation?.effectiveAt).isAfter(new Date());
            if (isEffectiveAtInTheFuture) {
                return true;
            }
        });
        return Boolean(futurePrimaryCompensationsemployeeCompensations.length);
    }

    async determineFormEditableState(): Promise<void> {
        const currentSalaryIsAPrimaryComp = this.allEmployeePrimaryCompensations
            .filter((comp) => comp.isPrimary)
            .some((compensation) => compensation.id === this.salary.id);
        if (
            this.allEmployeePrimaryCompensations.length === 0 ||
            (this.allEmployeePrimaryCompensations.length === 1 && currentSalaryIsAPrimaryComp)
        ) {
            this.setCompensationAsPrimary();
            return;
        }
        const isThereAPrimaryCompensationInTheFuture = this.determineIfThereIsAPrimaryCompensationInTheFuture(
            this.allEmployeePrimaryCompensations
        );
        if (isThereAPrimaryCompensationInTheFuture) {
            return;
        }
        if (!this.employee.isPayrollSyncEnabled) {
            if (this.employeeCurrentPrimaryCompensations && this.employeeCurrentPrimaryCompensations.length > 0) {
                this.mustMarkCompensationAsPrimary = false;
                return;
            }
            this.setCompensationAsPrimary();
            return;
        }
        const lastRecurringPayrollEndDate = await this.payrollService.getLastRecurringPayrollEndDate(
            this.auth.company.id
        );
        const primaryCompEndDates: Date[] | null[] = this.employeeCurrentPrimaryCompensations.map(
            (primaryComp: Salary) => primaryComp.endsAt
        );
        const employeeHasPrimaryWithNoEndDate: boolean = this.employeeCurrentPrimaryCompensations.some(
            (compensation) => {
                return compensation.endsAt === null;
            }
        );
        const employeeHasPrimaryCompEndingAfterLastPayroll: boolean = primaryCompEndDates.some((endDate) => {
            const parsedEndDate = parse(endDate).getTime();
            const parsedLastRecurringPayrollEndDate = parse(lastRecurringPayrollEndDate).getTime();

            return parsedEndDate > parsedLastRecurringPayrollEndDate;
        });

        if (
            lastRecurringPayrollEndDate === null ||
            employeeHasPrimaryCompEndingAfterLastPayroll ||
            employeeHasPrimaryWithNoEndDate
        ) {
            this.mustMarkCompensationAsPrimary = false;
            return;
        }
        this.setCompensationAsPrimary();
    }

    setCompensationName(): void {
        if (this.compensationMode === CompensationMode.new || this.mustMarkCompensationAsPrimary) {
            this.salary.name = this.translateService.instant('forms.employees-salary.regularPay');
            this.disableCompensationName = true;
            return;
        }
        this.disableCompensationName = false;
        this.salary.name = '';
    }

    shouldVacationPayBeDisplayed(isEditMode: boolean, salary: Salary): boolean {
        return isEditMode && salary.vacationPayPercentage > 0;
    }

    onRemoveVacationPay(): void {
        this.salary.vacationPayPercentage = null;
        this.showVacationPayPercentage = false;
    }

    /**
     * We disable this form if there is a pending payroll
     * and the new salary start date is before the pending payroll's end date
     * If this logic changes, this function should be modified
     */
    setFormEnabledStatus(): void {
        this.disableSaveButton = this.compensationService.enablePayrollForm(
            this.employee,
            this.salary,
            this.pendingPayrollEndDate
        );
        this.disabledSaveButtonEmitter.emit(this.disableSaveButton);
    }

    private async checkPendingPayrollStatus(): Promise<Date> {
        const payrollPendingStatusEndDate: Date = await this.payrollService
            .getPendingPayrollStatus()
            .then(({ endDate }: PendingPayrollStatus) => {
                return endDate;
            });
        return payrollPendingStatusEndDate;
    }
}
