<form #form="ngForm">
    <div class="app-grid">
        <div class="columns-4 small-columns-12">
            <ui-date-picker
                label="{{ 'forms.employees-terminate.lastDayOfWork' | appTranslate }}"
                name="lastDayOfWorkOn"
                [ngModel]="employee.lastDayOfWorkOn | appDate: 'yyyy-MM-dd'"
                (ngModelChange)="employee.lastDayOfWorkOn = $event; onLastDayOfWorkOnChanged()"
                placeholder="{{ 'forms.employees-terminate.selectADate' | appTranslate }}"
                required
                #lastDayOfWorkOn="ngModel"
            ></ui-date-picker>
        </div>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.employees-terminate.terminationType' | translate }}</mat-label>
            <mat-select
                name="terminationReasonCode"
                required
                #terminationReasonCode="ngModel"
                placeholder="{{ 'forms.employees-terminate.selectATerminationType' | translate }}"
                [(ngModel)]="employee.terminationReasonCode"
                (selectionChange)="onTerminationTypeChange()"
            >
                <mat-option *ngFor="let tCode of terminationCodes | keyvalue: valueAscOrder" [value]="tCode.key">
                    <span translate="forms.add-terminated.job.termination.TERMINATION_CODES.{{ tCode.key }}"></span
                ></mat-option>
            </mat-select>
            <mat-error *ngIf="terminationReasonCode.invalid">{{ terminationReasonCode.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.employees-terminate.terminationReason' | translate }}</mat-label>
            <input
                matInput
                type="text"
                placeholder="{{ 'forms.employees-terminate.terminationReasonPlaceholder' | translate }}"
                required
                #comments="ngModel"
                name="comments"
                [(ngModel)]="employee.terminationComments"
            />
            <mat-error *ngIf="comments.invalid">{{ comments.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <ng-container *ngIf="showBenefitExtensionOption && isNotProtectedLeave && isNotQuitLeave">
        <app-segment-header
            title="{{ 'forms.employees-terminate.extensionOfBenefits' | translate }}"
        ></app-segment-header>
        <div class="app-grid">
            <mat-checkbox class="padding-bottom-2" (change)="toggleHasBenefitsExtension()">
                <span class="text-color">{{
                    'forms.employees-terminate.differentDateThanLastDayOfWork' | translate
                }}</span>
            </mat-checkbox>
            <ng-container *ngIf="hasExtendedBenefits">
                <p [innerHTML]="'forms.employees-terminate.uponSubmissionOfTermination' | translate"></p>

                <div class="columns-4 small-columns-12">
                    <ui-date-picker
                        label="{{ 'forms.employees-terminate.employeesBenefitsEndDate' | appTranslate }}"
                        name="datepicker"
                        [ngModel]="benefitExtensionDate"
                        (ngModelChange)="setBenefitExtensionDate($event)"
                        required
                        #datePicker="ngModel"
                    ></ui-date-picker>
                </div>
            </ng-container>
        </div>
    </ng-container>
</form>
