<form #form="ngForm">
    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.add-terminated.payroll.compensationType' | translate }}</mat-label>
            <mat-select
                name="compensationType"
                [required]="isRequired()"
                #compensationType="ngModel"
                [(ngModel)]="salary.frequency"
            >
                <mat-option>{{ 'forms.add-terminated.payroll.notApplicable' | translate }}</mat-option>
                <mat-option
                    *ngFor="let compensationType of compensationTypes | keyvalue"
                    [value]="compensationType.key"
                    >{{ compensationType.value | appTranslateOptions: compensationTypeDisplayOptions }}</mat-option
                >
            </mat-select>
            <mat-error *ngIf="compensationType.invalid">{{ compensationType.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ salary.frequencyLabel | translate }}</mat-label>
            <input
                matInput
                type="number"
                [required]="isRequired()"
                name="rate"
                #rate="ngModel"
                [(ngModel)]="salary.rate"
            />
            <mat-error *ngIf="rate.invalid">{{ rate.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{ 'forms.add-terminated.payroll.hoursPerWeek' | translate }}</mat-label>
            <input
                matInput
                type="number"
                [required]="isRequired()"
                name="hoursPerWeek"
                #hoursPerWeek="ngModel"
                [(ngModel)]="salary.hoursPerWeek"
            />
            <mat-error *ngIf="hoursPerWeek.invalid">{{ hoursPerWeek.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label>{{
                'forms.add-terminated.payroll.salaryEffectiveDate'
                    | translate: { frequency: salary.frequencyLabel | translate }
            }}</mat-label>
            <input
                matInput
                [required]="isRequired()"
                name="effectiveAt"
                #effectiveAt="ngModel"
                [(ngModel)]="salary.effectiveAt"
                [matDatepicker]="effectiveAtPicker"
            />
            <mat-datepicker-toggle matSuffix [for]="effectiveAtPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #effectiveAtPicker></mat-datepicker>

            <mat-error *ngIf="effectiveAt.invalid">{{ effectiveAt.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <div class="app-grid">
        <mat-form-field class="columns-4 small-columns-12">
            <mat-label appRemoveAriaOwns>{{ 'forms.add-terminated.payroll.currency' | translate }}</mat-label>
            <mat-select name="currency" required #currency="ngModel" [(ngModel)]="employee.currency">
                <mat-option>{{ 'none' | translate }}</mat-option>
                <mat-option *ngFor="let currency of currencies" [value]="currency.code"
                    >{{ currency.code }}<span *hideFrench> - {{ currency.name }}</span>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="currency.invalid">{{ currency.errors | formErrors }}</mat-error>
        </mat-form-field>
    </div>

    <ng-container *ngIf="canDoPayroll">
        <app-segment-header title="forms.add-terminated.payroll.humiPayroll"></app-segment-header>
        <div class="app-grid">
            <mat-slide-toggle
                class="columns-12"
                name="isPayrollSyncEnabled"
                [(ngModel)]="employee.isPayrollSyncEnabled"
                color="primary"
            >
                {{ 'forms.add-terminated.payroll.autoSync' | translate }}
            </mat-slide-toggle>
        </div>
    </ng-container>
</form>
